import {
  SET_DEVICE_EV_LIST,
  SET_EVENT_LIST,
  SET_SELECTED_SN,
  SET_EVENT_DETAIL,
} from '../constants/Event';

const initState = {
  deviceEvList: [],
  eventList: [],
  eventDetail: {},
  selectedSn: '',
};

const event = (state = initState, action) => {
  switch (action.type) {
    case SET_DEVICE_EV_LIST:
      return {
        ...state,
        deviceEvList: action.data,
      };
    case SET_EVENT_LIST:
      return {
        ...state,
        eventList: action.data,
      };
    case SET_SELECTED_SN:
      return {
        ...state,
        selectedSn: action.data,
      };
    case SET_EVENT_DETAIL:
      return {
        ...state,
        eventDetail: action.payload,
      };
    default:
      return state;
  }
};

export default event;
