import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import {
  GET_TREE_ORG,
  GET_DETAIL_ORG,
  INSERT_ORG,
  UPDATE_ORG,
  DELETE_ORG,
} from '../constants/Org';
import { setTreeOrg, setDetailOrg, getTreeOrg } from '../actions/Org';
import { showAuthMessage } from '../actions/Auth';
import { appIntl } from 'IntlGlobalProvider';

import ApiService from 'services/ApiService';
import utils from 'utils';

export function* callTreeOrg() {
  yield takeEvery(GET_TREE_ORG, function* ({ payload }) {
    //let url = '/unit/list';
    const { unitCd } = utils.userInfo();
    let url = `/unit/childList/${unitCd}`;

    if (payload === 'regist') url = '/user/unitList';

    try {
      const list = yield call(ApiService.get, url, {});
      if (list) {
        if (payload === 'regist') yield put(setTreeOrg(list.data));
        else {
          let result = [];
          result.push(list.data);
          yield put(setTreeOrg(result));
        }
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callDetailOrg() {
  yield takeEvery(GET_DETAIL_ORG, function* ({ payload }) {
    const url = `/unit/list/${payload}`;

    try {
      const list = yield call(ApiService.get, url, {});
      if (list) {
        yield put(setDetailOrg(list.data));
      }
    } catch (err) {
      console.log('callDetailOrg err >>>', err);
      yield put(showAuthMessage(err));
    }
  });
}

export function* callInsertOrg() {
  yield takeEvery(INSERT_ORG, function* ({ payload }) {
    try {
      const { unitNm, telNo, address, radius, parentUnitCd, fileCd } = payload;
      const url = '/unit';
      const params = { unitNm, telNo, address, radius, parentUnitCd, fileCd };
      const res = yield call(ApiService.post, url, params);
      if (res.statusCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );
        yield put(getTreeOrg());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callUpdateOrg() {
  yield takeEvery(UPDATE_ORG, function* ({ payload }) {
    try {
      const { unitCd, unitNm, telNo, address, radius, parentUnitCd, fileCd } =
        payload;
      const url = `/unit/${unitCd}`;
      const params = { unitNm, telNo, address, radius, parentUnitCd, fileCd };
      const res = yield call(ApiService.patch, url, params);
      if (res.statusCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );
        yield put(getTreeOrg());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callDeleteOrg() {
  yield takeEvery(DELETE_ORG, function* ({ payload }) {
    try {
      const { unitCd } = payload;
      const url = '/unit/' + unitCd;
      const params = {};
      const res = yield call(ApiService.delete, url, params);
      if (res.statusCode === 201) {
        yield put(getTreeOrg());
      } else {
        utils.openNotificationWithIcon('error', res.statusMsg);
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(callTreeOrg),
    fork(callDetailOrg),
    fork(callInsertOrg),
    fork(callUpdateOrg),
    fork(callDeleteOrg),
  ]);
}
