import {
  GET_AED_LIST,
  SET_AED_LIST,
  SET_SELECTED_AED,
  GET_AED_DETAIL,
  SET_AED_DETAIL,
  INSERT_AED,
  UPDATE_AED,
  DELETE_AED,
  GET_APPR_LIST,
  SET_APPR_LIST,
  SET_SELECTED_APPR,
  GET_APPR_DETAIL,
  SET_APPR_DETAIL,
  SAVE_APPR,
  SET_APPR_CNT,
  ON_WS_CONNECT,
  GET_AED_HIS_LIST,
  SET_AED_HIS_LIST,
  GET_DEVICE_STATUS_CNT,
  SET_DEVICE_STATUS_CNT,
  GET_UPCOMMING_LIST,
  SET_UPCOMMING_LIST,
  GET_STATUS_NOW,
  SET_STATUS_NOW,
  GET_STATUS_MONTHLY,
  SET_STATUS_MONTHLY,
  GET_IMPORT_EXCEL_AED,
} from '../constants/Aed';

export const getAedList = (data) => {
  console.log('getAedList action >>>>>>', data);
  return {
    type: GET_AED_LIST,
    payload: data,
  };
};

export const setAedList = (data) => {
  return {
    type: SET_AED_LIST,
    payload: data,
  };
};

export const setSelectedAed = (data) => {
  return {
    type: SET_SELECTED_AED,
    data,
  };
};

export const getAedDetail = (data) => {
  return {
    type: GET_AED_DETAIL,
    payload: data,
  };
};

export const setAedDetail = (data) => {
  return {
    type: SET_AED_DETAIL,
    payload: data,
  };
};

export const insertAed = (data) => {
  return {
    type: INSERT_AED,
    payload: data,
  };
};

export const updateAed = (data) => {
  return {
    type: UPDATE_AED,
    payload: data,
  };
};

export const deleteAed = (data) => {
  return {
    type: DELETE_AED,
    payload: data,
  };
};

export const getApprList = (data) => {
  return {
    type: GET_APPR_LIST,
    payload: data,
  };
};

export const setApprList = (data) => {
  return {
    type: SET_APPR_LIST,
    payload: data,
  };
};

export const setSelectedAppr = (data) => {
  return {
    type: SET_SELECTED_APPR,
    data,
  };
};

export const getApprDetail = (data) => {
  return {
    type: GET_APPR_DETAIL,
    payload: data,
  };
};

export const setApprDetail = (data) => {
  return {
    type: SET_APPR_DETAIL,
    payload: data,
  };
};

export const saveAppr = (data) => {
  return {
    type: SAVE_APPR,
    payload: data,
  };
};

export const setApprCnt = (data) => {
  return {
    type: SET_APPR_CNT,
    data,
  };
};

export const onWsConnect = (data) => {
  return {
    type: ON_WS_CONNECT,
    data,
  };
};

export const getAedHisList = (data) => {
  return {
    type: GET_AED_HIS_LIST,
    payload: data,
  };
};

export const setAedHisList = (data) => {
  return {
    type: SET_AED_HIS_LIST,
    payload: data,
  };
};

export const getDeviceStatusCnt = (data) => {
  return {
    type: GET_DEVICE_STATUS_CNT,
    payload: data,
  };
};

export const setDeviceStatusCnt = (data) => {
  return {
    type: SET_DEVICE_STATUS_CNT,
    payload: data,
  };
};

export const getUpcommingList = (data) => {
  return {
    type: GET_UPCOMMING_LIST,
    payload: data,
  };
};

export const setUpcommingList = (data) => {
  return {
    type: SET_UPCOMMING_LIST,
    payload: data,
  };
};

export const getStatusNow = (data) => {
  return {
    type: GET_STATUS_NOW,
    payload: data,
  };
};

export const setStatusNow = (data) => {
  return {
    type: SET_STATUS_NOW,
    payload: data,
  };
};

export const getStatusMonthly = (data) => {
  return {
    type: GET_STATUS_MONTHLY,
    payload: data,
  };
};

export const setStatusMonthly = (data) => {
  return {
    type: SET_STATUS_MONTHLY,
    payload: data,
  };
};

export const getImportExcel = (data, params) => {
  // console.log('importExcel >>>', data);
  return {
    type: GET_IMPORT_EXCEL_AED,
    payload: data,
    params: params,
  };
};
