import {
  SET_LIST_LANG,
  SET_LIST_TERMS,
  SET_CUR_LANG,
  SET_IMPORT_EXCEL,
} from '../constants/Lang';

const initState = {
  listLang: [],
  listNotUseLang: [],
  listTerms: [],
  curLang: { langCd: '', langNm: '' },
  excelImportData: undefined,
};

const lang = (state = initState, action) => {
  switch (action.type) {
    case SET_LIST_LANG:
      return {
        ...state,
        listLang: action.data.filter((item) => item.useYn === 'Y'),
        listNotUseLang: action.data.filter((item) => item.useYn !== 'Y'),
      };
    case SET_LIST_TERMS:
      return {
        ...state,
        listTerms: action.data,
      };
    case SET_CUR_LANG:
      return {
        ...state,
        curLang: action.data,
      };
    case SET_IMPORT_EXCEL:
      return {
        ...state,
        excelImportData: action.data,
      };
    default:
      return state;
  }
};

export default lang;
