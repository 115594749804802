import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  .ant-picker-input > input-disabled,
  .ant-picker-input > input[disabled] {
    color: #6e6e6e;
  }
`;

const StyledForm = (props) => {
  const { children } = props;
  return <Styled>{children}</Styled>;
};

export default StyledForm;
