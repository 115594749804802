import React, { useState, useEffect } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import fetch from 'auth/FetchInterceptor';
import utils from 'utils';
import { useIntl } from 'react-intl';

const ChangePwd = ({ userId, close }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const rules = {
    password: [
      {
        required: true,
        //message: 'Please input your password',
        message: formatMessage({ id: 'message.account.0000001' }),
      },
    ],
    newPassword: [
      {
        required: true,
        //message: 'Please input your new password',
        message: formatMessage({ id: 'message.account.0000005' }),
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if ('' === value) {
            return Promise.resolve();
          }

          if (utils.chkPwd(value)) {
            return Promise.resolve();
          } else {
            return Promise.reject(
              formatMessage({ id: 'message.account.0000007' }) // '비밀번호는 8 ~ 15자 영문, 숫자 조합으로 입력해주세요.'
            );
          }
        },
      }),
    ],
    confirm: [
      {
        required: true,
        //message: 'Please confirm your password!',
        message: formatMessage({ id: 'message.account.0000002' }),
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue('newPassword') === value) {
            return Promise.resolve();
          }
          // return Promise.reject('Passwords do not match!');
          return Promise.reject(
            formatMessage({ id: 'message.account.0000003' })
          );
        },
      }),
    ],
  };

  const onSave = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          userId: userId,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        };

        console.log('data >>>', data);

        setLoading(true);

        fetch({
          url: '/user/upPass',
          method: 'post',
          headers: {
            'public-request': 'true',
          },
          data: data,
        }).then((resp) => {
          if (resp) {
            console.log('resp >>>', resp);
            if (resp.resultCode === 201) {
              utils.openNotificationWithIcon(
                'success',
                '성공적으로 변경되었습니다.'
              );
              form.resetFields();
              close();
            } else {
              //Modal.error({ title: '기존 비밀번호가 일치하지 않습니다.' });
              Modal.error({
                title: formatMessage({ id: 'message.account.0000006' }),
              });
            }
          }
          setLoading(false);
        });

        setLoading(false);
      })
      .catch((info) => {
        console.log('Update Failed:', info);
      });
  };

  useEffect(() => {}, [userId]);
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="register-form"
        onFinish={onSave}
        scrollToFirstError
      >
        <Form.Item
          name="oldPassword"
          //label="Password"
          label={formatMessage({ id: 'label.chpwd.0000001' })}
          rules={rules.password}
        >
          <Input.Password placeholder="Password" prefix={<LockOutlined />} />
        </Form.Item>
        <Form.Item
          name="newPassword"
          //label="New Password"
          label={formatMessage({ id: 'label.chpwd.0000002' })}
          rules={rules.newPassword}
        >
          <Input.Password placeholder="Password" prefix={<LockOutlined />} />
        </Form.Item>
        <Form.Item
          name="confirm"
          // label="Confirm Password"
          label={formatMessage({ id: 'label.chpwd.0000003' })}
          rules={rules.confirm}
        >
          <Input.Password
            placeholder="Confirm Password"
            prefix={<LockOutlined />}
          />
        </Form.Item>

        <Form.Item className="mt-4">
          <Button type="primary" htmlType="submit" block loading={loading}>
            {/* Save */ formatMessage({ id: 'btn.save' })}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChangePwd;
