import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { WEB_SOCKET_URL } from 'configs/AppConfig';
import { onWsConnect } from 'redux/actions/Aed';

const WebSocketContext = React.createContext(null);
export { WebSocketContext };

const WebSocketProvider = ({ children }) => {
  let ws = useRef();
  const dispatch = useDispatch();
  // console.log('process.env >>>>', process.env);
  const WEB_SOCKET_URL = process.env.REACT_APP_WS_URL;

  function connect() {
    console.log('connect call ' + ws.current);
    if (!ws.current) {
      ws.current = new WebSocket(WEB_SOCKET_URL);
      ws.current.onopen = () => {
        console.log('connected to ' + WEB_SOCKET_URL);
        dispatch(onWsConnect(true));
      };
      ws.current.onclose = (error) => {
        console.log('disconnect from ' + WEB_SOCKET_URL);
        ws.current = undefined;
        // dispatch(onWsConnect(false));
        setTimeout(function () {
          connect();
        }, 1000);
        // console.log(error);
      };
      ws.current.onerror = (error) => {
        console.log('connection error ' + error);
        dispatch(onWsConnect(false));
        if (!!ws) ws.close();
      };
    }
  }

  // useEffect(() => {
  connect();
  // }, []);

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
