import {
  GET_TREE_ORG,
  SET_TREE_ORG,
  SET_SELECTED_ORG,
  GET_DETAIL_ORG,
  SET_DETAIL_ORG,
  INSERT_ORG,
  UPDATE_ORG,
  DELETE_ORG,
} from "../constants/Org";

export const getTreeOrg = (data) => {
  return {
    type: GET_TREE_ORG,
    payload: data,
  };
};

export const setTreeOrg = (data) => {
  return {
    type: SET_TREE_ORG,
    data,
  };
};

export const setSelectedOrg = (data) => {
  return {
    type: SET_SELECTED_ORG,
    data,
  };
};

export const getDetailOrg = (data) => {
  return {
    type: GET_DETAIL_ORG,
    payload: data,
  };
};

export const setDetailOrg = (data) => {
  return {
    type: SET_DETAIL_ORG,
    data,
  };
};

export const insertOrg = (data) => {
  return {
    type: INSERT_ORG,
    payload: data,
  };
};

export const updateOrg = (data) => {
  return {
    type: UPDATE_ORG,
    payload: data,
  };
};

export const deleteOrg = (data) => {
  return {
    type: DELETE_ORG,
    payload: data,
  };
};
