import React, { useState, useEffect } from 'react';
import { Tree, Card, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'redux/actions/Org';
import { useIntl } from 'react-intl';

const TreeOrg = ({ mode, handleClickTree, treeOrg }) => {
  const dispatch = useDispatch();
  const { Search } = Input;
  const { formatMessage } = useIntl();

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [defaultKeys, setDefaultKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [treeData, setTreeData] = useState([]);

  const onExpand = (keys) => {
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  };

  const dataList = [];
  const generateList = (data) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key, unitNm } = node;
      dataList.push({ key, unitNm });
      if (node.children) {
        generateList(node.children);
      }
    }
  };

  generateList(treeData);

  const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  };

  // 한글인지 체크
  const korCheckValue = (value) => {
    const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
    if (regExp.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  // 완성형 글자인지 체크
  const korCompleteValue = (str) => {
    let i;
    for (i = 0; i < str.length; i++) {
      if (str.charAt(i) < '가' || str.charAt(i) > '힣') {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    let result = true;
    if (korCheckValue(value)) {
      if (!korCompleteValue(value)) {
        result = false;
      }
    }
    console.log('result >>>', result, value);
    if (result) {
      const keys = dataList
        .map((item) => {
          if (item && item.unitNm.indexOf(value) > -1) {
            return getParentKey(item.key, treeData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);

      setSearchValue(value);
      setAutoExpandParent(true);

      if (value !== '') {
        setExpandedKeys(keys);
      } else {
        setExpandedKeys(defaultKeys);
      }
    }
  };

  const loop = (data) => {
    return data.map((item) => {
      const index = item.unitNm.indexOf(searchValue);
      const beforeStr = item.unitNm.substr(0, index);
      const afterStr = item.unitNm.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.unitNm}</span>
        );

      if (item.children) {
        return {
          title,
          key: item.key,
          unitCd: item.unitCd,
          unitNm: item.unitNm,
          children: loop(item.children),
        };
      }

      return {
        title,
        unitCd: item.unitCd,
        unitNm: item.unitNm,
        key: item.unitCd,
      };
    });
  };

  const handleClickNode = (selectedKeys, e) => {
    handleClickTree(e.node);
    // const code = e.node.unitCd;
    // const selectedOrg = { unitCd: code, unitNm: e.node.unitNm };

    // dispatch(actions.setSelectedOrg(selectedOrg));
    // dispatch(actions.getDetailOrg(code));
  };

  const getTree = () => {
    dispatch(actions.getTreeOrg(mode));
  };

  useEffect(() => {
    try {
      setTreeData(null);
      if (treeOrg && treeOrg.length > 0) {
        const keys = [treeOrg[0].key];
        setExpandedKeys(keys);
        setDefaultKeys(keys);
      }
      setTreeData(treeOrg);
    } catch (e) {
      console.error('error >>', e);
    }
  }, [treeOrg]);

  useEffect(() => {
    getTree();
  }, []);

  return (
    <div>
      <Input
        style={{ marginBottom: 8 }}
        // placeholder="Search"
        placeholder={
          /* Please enter your search term */ formatMessage({
            id: 'message.search.term',
          })
        }
        onKeyUp={handleSearch}
      />
      <Tree
        className="draggable-tree"
        defaultExpandedKeys={defaultKeys}
        expandedKeys={expandedKeys}
        draggable
        // onDragEnter={this.onDragEnter}
        // onDrop={this.onDrop}
        onExpand={(e) => onExpand(e)}
        autoExpandParent={autoExpandParent}
        treeData={loop(treeData)}
        onSelect={handleClickNode}
      />
    </div>
  );
};

export default TreeOrg;
