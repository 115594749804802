import {
  SET_PAD_LIST,
  SET_SELECTED_PAD,
  SET_PAD_DETAIL,
} from '../constants/Pad';

const initState = {
  padList: [],
  padDetail: {},
  selectedPad: '',
};

const pad = (state = initState, action) => {
  switch (action.type) {
    case SET_PAD_LIST:
      return {
        ...state,
        padList: action.data,
      };
    case SET_SELECTED_PAD:
      return {
        ...state,
        selectedPad: action.data,
      };
    case SET_PAD_DETAIL:
      return {
        ...state,
        padDetail: action.payload,
      };
    default:
      return state;
  }
};

export default pad;
