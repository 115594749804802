import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_LTR,
} from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig';

export const APP_NAME = 'Mediana';
export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL; // env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY; // 구글맵 API KEY

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_TOP,
  topNavColor: '#185BB9',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  direction: DIR_LTR,
};

// Web Soket URL 설정
const wsUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'ws://localhost:8080';
    case 'production':
      return 'wss://mediana-api-dev.ciesta.co:8090';
    case 'test':
      return 'ws://localhost:8080';
    default:
      break;
  }
};

export const WEB_SOCKET_URL = process.env.WS_URL; //wsUrl();
