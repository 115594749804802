import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GlobalStyleComponent } from 'styled-components';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/setting`}
          component={lazy(() => import(`./setting/organization`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/setting/account/`}
          component={lazy(() => import(`./setting/account/`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/setting/language/`}
          component={lazy(() => import(`./setting/language/`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/setting/organization/`}
          component={lazy(() => import(`./setting/organization`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/setting/screen-layout/`}
          component={lazy(() => import(`./setting/screen-layout`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/setting/device-pad/`}
          component={lazy(() => import(`./setting/pad`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/setting/device-battery/`}
          component={lazy(() => import(`./setting/battery`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/setting/device-aed/`}
          component={lazy(() => import(`./setting/aed`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/device-list/`}
          component={lazy(() => import(`./device`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/event-list/`}
          component={lazy(() => import(`./event`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/install-confirm/`}
          component={lazy(() => import(`./approval`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
