import {
  GET_PAD_LIST,
  SET_PAD_LIST,
  SET_SELECTED_PAD,
  GET_PAD_DETAIL,
  SET_PAD_DETAIL,
  INSERT_PAD,
  UPDATE_PAD,
  DELETE_PAD,
} from '../constants/Pad';

export const getPadList = (data) => {
  return {
    type: GET_PAD_LIST,
    payload: data,
  };
};

export const setPadList = (data) => {
  return {
    type: SET_PAD_LIST,
    payload: data,
  };
};

export const setSelectedPad = (data) => {
  return {
    type: SET_SELECTED_PAD,
    data,
  };
};

export const getPadDetail = (data) => {
  return {
    type: GET_PAD_DETAIL,
    payload: data,
  };
};

export const setPadDetail = (data) => {
  return {
    type: SET_PAD_DETAIL,
    payload: data,
  };
};

export const insertPad = (data) => {
  return {
    type: INSERT_PAD,
    payload: data,
  };
};

export const updatePad = (data) => {
  return {
    type: UPDATE_PAD,
    payload: data,
  };
};

export const deletePad = (data) => {
  return {
    type: DELETE_PAD,
    payload: data,
  };
};
