import {
  GET_BATTERY_LIST,
  SET_BATTERY_LIST,
  SET_SELECTED_BATTERY,
  GET_BATTERY_DETAIL,
  SET_BATTERY_DETAIL,
  INSERT_BATTERY,
  UPDATE_BATTERY,
  DELETE_BATTERY,
} from '../constants/Battery';

export const getBatteryList = (data) => {
  return {
    type: GET_BATTERY_LIST,
    payload: data,
  };
};

export const setBatteryList = (data) => {
  return {
    type: SET_BATTERY_LIST,
    payload: data,
  };
};

export const setSelectedBattery = (data) => {
  return {
    type: SET_SELECTED_BATTERY,
    data,
  };
};

export const getBatteryDetail = (data) => {
  return {
    type: GET_BATTERY_DETAIL,
    payload: data,
  };
};

export const setBatteryDetail = (data) => {
  return {
    type: SET_BATTERY_DETAIL,
    payload: data,
  };
};

export const insertBattery = (data) => {
  return {
    type: INSERT_BATTERY,
    payload: data,
  };
};

export const updateBattery = (data) => {
  return {
    type: UPDATE_BATTERY,
    payload: data,
  };
};

export const deleteBattery = (data) => {
  return {
    type: DELETE_BATTERY,
    payload: data,
  };
};
