import {
  SET_BATTERY_LIST,
  SET_SELECTED_BATTERY,
  SET_BATTERY_DETAIL,
} from '../constants/Battery';

const initState = {
  batteryList: [],
  batteryDetail: {},
  selectedBattery: '',
};

const battery = (state = initState, action) => {
  switch (action.type) {
    case SET_BATTERY_LIST:
      return {
        ...state,
        batteryList: action.data,
      };
    case SET_SELECTED_BATTERY:
      return {
        ...state,
        selectedBattery: action.data,
      };
    case SET_BATTERY_DETAIL:
      return {
        ...state,
        batteryDetail: action.payload,
      };
    default:
      return state;
  }
};

export default battery;
