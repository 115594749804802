import {
  SET_AED_LIST,
  SET_SELECTED_AED,
  SET_AED_DETAIL,
  SET_APPR_LIST,
  SET_APPR_DETAIL,
  SET_SELECTED_APPR,
  SET_APPR_CNT,
  ON_WS_CONNECT,
  SET_AED_HIS_LIST,
  SET_DEVICE_STATUS_CNT,
  SET_UPCOMMING_LIST,
  SET_STATUS_NOW,
  SET_STATUS_MONTHLY,
} from '../constants/Aed';

const initState = {
  aedList: [],
  aedDetail: {},
  selectedAed: '',
  apprList: [],
  apprDetail: [],
  selectedAppr: '',
  apprCnt: 0,
  onWsConnect: false,
  aedHisList: [],
  deviceStatusCnt: {},
  upcommingList: [],
  statusNow: {},
  statusMonthly: [],
};

const aed = (state = initState, action) => {
  switch (action.type) {
    case SET_AED_LIST:
      return {
        ...state,
        aedList: action.data,
      };
    case SET_SELECTED_AED:
      return {
        ...state,
        selectedAed: action.data,
      };
    case SET_AED_DETAIL:
      return {
        ...state,
        aedDetail: action.payload,
      };
    case SET_APPR_LIST:
      return {
        ...state,
        apprList: action.data,
      };
    case SET_SELECTED_APPR:
      return {
        ...state,
        selectedAppr: action.data,
      };
    case SET_APPR_DETAIL:
      return {
        ...state,
        apprDetail: action.payload,
      };
    case SET_APPR_CNT:
      return {
        ...state,
        apprCnt: action.data,
      };
    case ON_WS_CONNECT:
      return {
        ...state,
        onWsConnect: action.data,
      };
    case SET_AED_HIS_LIST:
      return {
        ...state,
        aedHisList: action.data,
      };
    case SET_DEVICE_STATUS_CNT:
      return {
        ...state,
        deviceStatusCnt: action.data,
      };
    case SET_UPCOMMING_LIST:
      return {
        ...state,
        upcommingList: action.data,
      };
    case SET_STATUS_NOW:
      return {
        ...state,
        statusNow: action.data,
      };
    case SET_STATUS_MONTHLY:
      return {
        ...state,
        statusMonthly: action.data,
      };
    default:
      return state;
  }
};

export default aed;
