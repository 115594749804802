import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Lang from './Lang';
import Org from './Org';
import Account from './Account';
import Pad from './Pad';
import Battery from './Battery';
import Aed from './Aed';
import Event from './Event';
import Dashboard from './Dashboard';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  lang: Lang,
  org: Org,
  account: Account,
  pad: Pad,
  battery: Battery,
  aed: Aed,
  event: Event,
  dashboard: Dashboard,
});

export default reducers;
